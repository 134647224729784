<ng-container *ngIf="!squareBanner; else square">
  <div class="ratio" style="--bs-aspect-ratio: 42%;">
    <div class="event-poster" [style]="{'background-image': 'url('+item.path_file_banner+'), url(/assets/images/addtix-placeholder.svg)'}"></div>
  </div>
</ng-container>
<ng-template #square>
  <div class="ratio" style="--bs-aspect-ratio: 59%;">
    <div class="event-poster" [style]="{'background-image': 'url('+item.path_file_banner+'), url(/assets/images/addtix-placeholder.svg)'}"></div>
  </div>
</ng-template>
<div class="event-body">
  <p class="date">{{item | eventDate}}</p>
  <a [href]="item?.cta_link ? item?.cta_link : '/event/'+item.uri" class="title" placement="top" [ngbTooltip]="item.name">{{item.name}}</a>
  <p class="venue">{{item.venues?.title || '-'}}, {{item.province_name}}</p>
  <div class="price" *ngIf="item.min_price">
    <span>{{ 'Start From' | translate }}</span><br />
    {{item.min_price | currency : item.currency + ' '}}
  </div>
</div>
