<footer>
  <div class="footer-content left-content">
    <img src="/assets/images/logo.png" class="logo" alt="">
    <div class="link-container">
      <a class="link" href="https://wa.me/6281318794988">
        <img src="/assets/images/icons/wa.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Whatsapp' | translate }}</p>
          <p>+6281318794988</p>
        </div>
      </a>
      <a class="link" href="mailto:info@addtix.id">
        <img src="/assets/images/icons/mail.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Email' | translate }}</p>
          <p>info@addtix.id</p>
        </div>
      </a>
    </div>
    <div class="link-container mt-3">
      <a class="link" >
        <img src="/assets/images/icons/address.svg" alt="">
        <div class="text-container">
          <p class="label">{{ 'Address' | translate }}</p>
          <p>Epiwalk Office Suite Level 5 Unit 529A,
            Jl. H. R. Rasuna Said, Jakarta 12940</p>
        </div>
      </a>
    </div>
  </div>
  <div class="footer-content center-content" *ngIf="!iS.isSukha">
    <h3>{{ 'Company' | translate }}</h3>
    <a [routerLink]="['/about-us']">{{ 'About Us' | translate }}</a>
  </div>
  <div class="footer-content center-content" *ngIf="!iS.isSukha">
    <h3>{{ 'Customer Care' | translate }}</h3>
    <a [routerLink]="['/faq']">{{ 'FAQs' | translate }}</a>
    <!-- <a [routerLink]="['/privacy-policy']">{{ 'Privacy & Policy' | translate }}</a> -->
    <!-- <a [routerLink]="['/terms-condition']">{{ 'Terms of Use' | translate }}</a> -->
  </div>
  <div class="footer-content center-content" *ngIf="iS.isSukha">
    <h3>{{ 'Customer Care' | translate }}</h3>
    <p class="w-75 fs-6">Produk atau jasa ini disediakan dan menjadi tanggung jawab ADDTix. Untuk
      layanan contact center ADDTix hubungi +6281318794988 atau info@addtix.id. Cek Syarat dan Ketentuan layanan lengkap di sini</p>
    <!-- <a [routerLink]="['/privacy-policy']">{{ 'Privacy & Policy' | translate }}</a> -->
    <!-- <a [routerLink]="['/terms-condition']">{{ 'Terms of Use' | translate }}</a> -->
  </div>
  <div class="footer-content right-content">
    <h3>{{ 'Security of Your Transaction' | translate }}</h3>
    <div class="brand-container">
      <ng-container *ngIf="!iS.isSukha; else brandSukha">
        <img src="/assets/images/brands/Mastercard-logo.svg" alt="">
        <img src="/assets/images/brands/Visa_Inc_logo.svg" alt="">
        <img src="/assets/images/brands/JCB_logo.svg" alt="">
        <!-- <img src="/assets/images/brands/4.svg" alt=""> -->
        <!-- <img src="/assets/images/brands/5.svg" alt=""> -->
      </ng-container>
      <ng-template #brandSukha>
        <img src="/assets/images/icons/mandiri.png" alt="">
      </ng-template>
    </div>
  </div>
</footer>
