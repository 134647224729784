import { Pipe, PipeTransform } from '@angular/core';
import { convertToTzDate } from '@cx-utils/date';

@Pipe({
  name: 'tz',
  pure: false,
})
export class TzPipe implements PipeTransform {
  transform(
    value: Date | string | number,
    format: string,
    type: 'number' | 'dateString' | 'date' = 'date',
    tz?: string
  ): string {
    return convertToTzDate(value, format, type, tz);
  }
}
