export const MAX_FILE_SIZE = 512000
export const ALLOWED_FILE_TYPE = ['application/pdf','image/png','image/jpg','image/jpeg']

export const SESSION_KEY = 'ctxYghd';
export const SECRET_KEY = 'raH4s1a';
export const EVENT_REF_COOKIE_KEY = 'cgxWWSas';
export const EVENT_REF_COOKIE_EXPIRED: number = 10;
export const EVENT_REF_COOKIE_EXPIRED_UNIT: string = 'minutes';
export const MONTH_SHORT_TEXT_LIST = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec']
export const PM_BANK_TRANSFER_LIST = [1];
export const PM_VIRTUAL_ACCOUNT_LIST = [4,6,13,5,11,12,43]
export const PM_EWALLET_LIST = [22,28,29,36,38,40,41,42]
export const PM_CREDIT_CARD_LIST = [3]
export const PM_OFFLINE_LIST = [37]

export const PM_CREDIT_CARD = 3;
export const PM_BANK_TRANSFER = 1;
export const PM_VA_MANDIRI = 4;
export const PM_VA_PERMATA = 6;
export const PM_VA_BCA = 13;
export const PM_VA_BNI = 5;
export const PM_VA_BRI = 11;
export const PM_VA_BSI = 12;
export const PM_VA_BJB = 43;
export const PM_EWALLET_LINK_AJA = 22;
export const PM_EWALLET_GOPAY = 28;
export const PM_EWALLET_OVO = 29;
export const PM_EWALLET_DANA = 36;
export const PM_EWALLET_SHOPEEPAY = 38;
export const PM_EWALLET_JENIUS = 40;
export const PM_EWALLET_QRIS = 41;
export const PM_EWALLET_ASTRAPAY = 42;
export const PM_OFFLINE_INDOMARET = 37;
export const PM_FREE_OF_CHARGE = 18;

export const PS_CHECKOUT = 1;
export const PS_WAITING = 2;
export const PS_PAID = 3;
export const PS_FAILED = 4;
export const PS_EXPIRED = 6;

