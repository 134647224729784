import { Pipe, PipeTransform } from '@angular/core';
import { Event } from '@cx-types/home';
import { convertToTzDate } from '@cx-utils/date';

@Pipe({
  name: 'eventDate'
})
export class EventDatePipe implements PipeTransform {

  transform(event: {start_date: string, end_date?: string}): string {
    let dateString = '';
    if (!!event.end_date && event.start_date !== event.end_date) {
      const startMonthYear = convertToTzDate(event.start_date, 'MM-YYYY', 'dateString');
      const endMonthYear = convertToTzDate(event.end_date, 'MM-YYYY', 'dateString');
      const startYear = convertToTzDate(event.start_date, 'YYYY', 'dateString');
      const endYear = convertToTzDate(event.end_date, 'YYYY', 'dateString');
      if (startYear === endYear) {
        if (startMonthYear === endMonthYear) {
          dateString = `${convertToTzDate(event.start_date, 'D', 'dateString')} - ${convertToTzDate(event.end_date, 'DD MMMM YYYY', 'dateString')}`;
        } else {
          dateString = `${convertToTzDate(event.start_date, 'D MMM', 'dateString')} - ${convertToTzDate(event.end_date, 'DD MMM YYYY', 'dateString')}`;
        }
      } else {
        dateString = `${convertToTzDate(event.start_date, 'D MMM YYYY', 'dateString')} - ${convertToTzDate(event.end_date, 'DD MMM YYYY', 'dateString')}`;
      }
    } else {
      dateString = convertToTzDate(event.start_date, 'D MMMM YYYY', 'dateString');
    }
    return dateString;
  }

}
