import { Component, Input } from '@angular/core';

@Component({
  selector: 'cx-image',
  template: `
    <img
      [src]="src"
      [alt]="alt"
      [width]="width"
      [height]="height"
      [class]="className"
      loading="lazy"
      style="aspect-ratio: auto {{ width }} / {{ height }};"
    />
  `,
  styles: [`
    img {
      max-width: 100%;
      height: auto;
      display: block;
    }
  `]
})
export class ImageComponent {
  @Input() src: string = '';
  @Input() alt: string = '';
  @Input() width: number = 0;
  @Input() height: number = 0;
  @Input() className: string = '';
}
