import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { LayoutComponent } from './components/layout/layout.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { LetDirective } from './directives/let.directive';
import { IncludesPipe } from './pipes/includes.pipe';
import { HttpClientModule } from '@angular/common/http';
import { TzPipe } from './pipes/tz.pipe';
import { EventCardComponent } from './components/event-card/event-card.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AssetPipe } from './pipes/asset.pipe';
import { EventDatePipe } from './pipes/event-date.pipe';
import {NgbDropdownModule, NgbToastModule, NgbTooltipModule} from '@ng-bootstrap/ng-bootstrap';
import { EmptyComponent } from './components/empty/empty.component';
import {TranslateModule} from "@ngx-translate/core";
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { CountdownPipe } from './pipes/countdown.pipe';
import { FormsModule } from '@angular/forms';

const components = [
  SpinnerComponent,
  HeaderComponent,
  FooterComponent,
  EventCardComponent,
  BreadcrumbComponent,
  EmptyComponent,
  LayoutComponent
];

const pipes = [ IncludesPipe, TzPipe, AssetPipe, EventDatePipe, CountdownPipe ];

const directives = [ LetDirective ];

@NgModule({
  declarations: [ ...components, ...pipes, ...directives, PageNotFoundComponent ],
  exports: [ ...components, ...pipes, ...directives ],
	imports: [
		HttpClientModule,
		NgbTooltipModule,
		NgbDropdownModule,
		CommonModule,
    FormsModule,
		RouterModule,
		TranslateModule,
		NgbToastModule
	]
})
export class SharedModule { }
