<footer>
  <div class="footer-content left-content">
    <cx-image src="/assets/images/logo.png" class="logo" alt="Logo" [width]="82" [height]="40"></cx-image>
    <div class="link-container">
      <a class="link" [attr.href]="!iS.isSukha ? 'https://wa.me/6282125999908' : null">
        <cx-image src="/assets/images/icons/wa.svg" alt="Whatsapp" [width]="24" [height]="24"></cx-image>
        <div class="text-container">
          <p class="label">{{ 'Whatsapp' | translate }}</p>
          <p>+62821-2599-9908</p>
        </div>
      </a>
      <a class="link" [attr.href]="!iS.isSukha ? 'mailto:info@addtix.id' : null">
        <cx-image src="/assets/images/icons/mail.svg" alt="Email" [width]="24" [height]="24"></cx-image>
        <div class="text-container">
          <p class="label">{{ 'Email' | translate }}</p>
          <p>info@addtix.id</p>
        </div>
      </a>
    </div>
    <div class="link-container mt-3">
      <a class="link" href="https://www.google.com/maps/place/Epiwalk/@-6.2181002,106.8304102,1421m/data=!3m2!1e3!4b1!4m6!3m5!1s0x2e69f40831e56edf:0x3baf62dc4159d8b1!8m2!3d-6.2181002!4d106.8352811!16s%2Fg%2F11cs3yl10c?entry=ttu&g_ep=EgoyMDI0MTIxMS4wIKXMDSoASAFQAw%3D%3D" target="_blank" >
        <img src="/assets/images/icons/address.svg" alt="Address">
        <div class="text-container">
          <p class="label">{{ 'Address' | translate }}</p>
          <p>Epiwalk Office Suite Level 5 Unit 529A,
            Jl. H. R. Rasuna Said, Jakarta 12940</p>
        </div>
      </a>
    </div>
  </div>
  <div class="footer-content center-content" *ngIf="!iS.isSukha && !iS.isOnePiece && !iS.isAK && !iS.isRichieRen">
    <h3>{{ 'Company' | translate }}</h3>
    <a [routerLink]="['/about-us']">{{ 'About Us' | translate }}</a>
  </div>
  <div class="footer-content center-content" *ngIf="!iS.isSukha && !iS.isOnePiece && !iS.isAK && !iS.isRichieRen">
    <h3>{{ 'Customer Care' | translate }}</h3>
    <a [routerLink]="['/faq']">{{ 'FAQs' | translate }}</a>
    <!-- <a [routerLink]="['/privacy-policy']">{{ 'Privacy & Policy' | translate }}</a> -->
    <!-- <a [routerLink]="['/terms-condition']">{{ 'Terms of Use' | translate }}</a> -->
  </div>
  <div class="footer-content center-content" [class.w-100]="iS.isSukha" *ngIf="iS.isSukha">
    <h3>{{ 'Customer Care' | translate }}</h3>
    <div class="w-75 fs-6 d-inline-block" [class.w-100]="iS.isSukha">
      <span>Produk atau jasa ini disediakan dan menjadi tanggung jawab ADDTix. Untuk
        layanan contact center ADDTix hubungi +62821-2599-9908 atau info@addtix.id. Cek Syarat dan Ketentuan layanan lengkap </span>
      <a href="https://bmri.id/sukhatncxaddtix" class="fs-6 d-inline-block">di sini</a>
    </div>
    <!-- <a [routerLink]="['/privacy-policy']">{{ 'Privacy & Policy' | translate }}</a> -->
    <!-- <a [routerLink]="['/terms-condition']">{{ 'Terms of Use' | translate }}</a> -->
  </div>
  <div class="footer-content right-content" *ngIf="!iS.isSukha">
    <h3>{{ 'Security of Your Transaction' | translate }}</h3>
    <div class="brand-container">
      <ng-container *ngIf="!iS.isSukha; else brandSukha">
        <cx-image src="/assets/images/brands/Mastercard-logo.svg" alt="Mastercard" [width]="41" [height]="32"></cx-image>
        <cx-image src="/assets/images/brands/Visa_Inc_logo.svg" alt="Visa" [width]="99" [height]="32"></cx-image>
        <cx-image src="/assets/images/brands/JCB_logo.svg" alt="JCB" [width]="41" [height]="32"></cx-image>
      </ng-container>
      <ng-template #brandSukha>
        <img src="/assets/images/icons/mandiri.png" alt="Mandiri">
      </ng-template>
    </div>
  </div>
</footer>
