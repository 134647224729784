import { Injectable } from '@angular/core';
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class IntegrationService {
  source = null
  _param1 = null
  _param2 = null
  constructor(
    private aR: ActivatedRoute,
  ) {
    this.aR.queryParams
      .subscribe(params => {
          this.source = params['source'];
        }
      );
  }

  initSource(){
    if(!this.source){
      this.source = this.aR.snapshot.queryParamMap.get('source');
    }
  }
  get isSukha(){
    return this.source === 'mandiri_sukha' || this.source === 'sukha';
  }
  set param1(params1: string){
    this._param1 = params1
  }
  get param1(){
    return this._param1;
  }
  set param2(params2: string){
    this._param2 = params2
  }
  get param2(){
    return this._param2;
  }
}
