import * as dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
import * as timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export function convertToTzDate(
  value: Date | string | number,
  format: string,
  type: 'number' | 'dateString' | 'date' = 'date',
  tz: string = 'local', // 'Asia/Hong_Kong',
  isEndRange: boolean = false
): string {
  let date: Date = new Date();
  switch (type) {
    case 'date':
      date = <Date>value;
      break;
    case 'dateString':
      if (isEndRange) {
        date = dayjs(value, 'YYYY-MM-DD').add(1, 'day').add(-1, 'second').toDate();
      } else {
        date = dayjs(value, 'YYYY-MM-DD').toDate();
      }
      break;
    case 'number':
      date = dayjs(value).toDate();
      break;
    default:
      break;
  }
  if (tz === 'local') {
    tz = dayjs.tz.guess();
  }
  return dayjs(date).tz(tz).format(format);
}

export function convertHourNumberToHuman(hourMinute: number): string {
  const hour = hourMinute > 0 ? hourMinute / 100 : 0;
  const start = dayjs().set('hour', hour).set('minute', 0);
  const end = dayjs()
    .set('hour', hour + 1)
    .set('minute', 0);

  return `${start.format('h:mm A')} - ${end.format('h:mm A')} HKT`;
}

export function convertSecondToTime(seconds: number) {
  let minutes = Math.floor(seconds / 60);
  let hours = Math.floor(minutes / 60);

  seconds = seconds % 60;
  minutes = minutes % 60;

  return {
    hours,
    minutes,
    seconds,
  };
}
